import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { setScaleIntroAnimation } from '../state/scaleIntroAnim';
import { setIntroStartModule } from '../state/introStartModule';
import { setMenuColor } from '../state/menuColor';
import { setIntroLoadedOnce } from '../state/introLoadedOnce';
import ArrowButton from '../common/ArrowButton';

const StyledWrapper = styled.div`
	align-items: center;
	background-color: ${({ theme }) => theme.colors.white};
	display: flex;
	flex-direction: column;
	gap: 17px;
	height: 100vh;
	justify-content: center;
	overflow-x: hidden;
	padding: 0 71px;
	position: relative;
	text-align: center;
	width: 100%;
	z-index: 1;
`;

const StyledBigHeader = styled.h1`
	font-family: ${({ theme }) => theme.fontFamily.primaryItalic};
	font-size: 40px;
	font-weight: ${({ theme }) => theme.fontWeight.light};
	line-height: 51px;
	z-index: 1;
	@media (min-width: 1366px) {
		line-height: 80px;
		font-size: 64px;
	}
`;
const StyledSmallHeader = styled.h2`
	font-family: ${({ theme }) => theme.fontFamily.primary};
	font-size: 20px;
	font-weight: ${({ theme }) => theme.fontWeight.light};
	line-height: 23px;
	max-width: 150px;
	z-index: 1;
`;

const Styled404 = styled.h1`
	color: ${({ theme }) => theme.colors.creamy};
	font-family: ${({ theme }) => theme.fontFamily.primary};
	font-size: 387px;
	font-weight: ${({ theme }) => theme.fontWeight.light};
	left: 50%;
	line-height: 465px;
	position: absolute;
	top: 50%;
	transform: translate(-50%, -50%);
	z-index: 0;
`;
const errorPage = ({ data: { errorData }, pageContext }) => {
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(setScaleIntroAnimation(true));
		dispatch(setIntroStartModule(false));
		dispatch(setMenuColor(true));
		dispatch(setIntroLoadedOnce(true));
	}, []);

	const pageData = errorData?.nodes?.[0] || '';
	const bigHeader = pageData?.contentBigHeader || '';
	const smallHeader = pageData?.contentSmallHeader || '';

	return (
		<StyledWrapper className="last">
			<Styled404>404</Styled404>
			<StyledBigHeader>{bigHeader}</StyledBigHeader>
			<ArrowButton to="/" arrowLeft />
			<StyledSmallHeader>{smallHeader}</StyledSmallHeader>
		</StyledWrapper>
	);
};
export default errorPage;

export const query = graphql`
	query ($locale: String) {
		errorData: allDatoCmsErrorPage(filter: { locale: { eq: $locale } }) {
			nodes {
				contentBigHeader
				contentSmallHeader
			}
		}
	}
`;
